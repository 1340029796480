<template>
<div></div>
</template>
<script>
import { useRouter } from 'vue-router'

export default {
  data () {
    const router = useRouter()
    window.localStorage.setItem('parceiro', this.$route.params.qrcode)
    router.push({ name: 'Home' })
  }
}
</script>
